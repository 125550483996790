import settings from '../settings';

export default {
  init() {
    $('.js-slider')
      .each(function (i, slider) {
        let mobile = $(slider)
          .attr('data-mobile');
        let adaptive = $(slider)
          .attr('data-adaptive');
        let dots = $(slider)
          .attr('data-dots') === 'false' ? false : true;
        let arrows = $(slider)
          .attr('data-arrows') === 'true' ? true : false;
        let autoplay = $(slider)
          .attr('data-autoplay') ? $(slider)
          .attr('data-autoplay') : false;
        let slidesToShow = adaptive ? Math.floor($(slider)
          .outerWidth() / $(slider)
          .children('li, .li')
          .outerWidth()) : 1;

        if (mobile) {
          if ((mobile === 'true' && settings.__isMobile) ||
            (mobile === 'middle' && settings.__isMobileTabletMiddle) ||
            (mobile === 'small' && settings.__isMobileTabletSmall) ||
            (mobile === 'mobile' && settings.__isMobileSmall)) {

            $(slider)
              .slick({
                slidesToShow: slidesToShow,
                slidesToScroll: slidesToShow,
                dots: dots,
                arrows: arrows,
                autoplay: autoplay,
                centerMode: true,
                centerPadding: '0'
              });
          }
        } else {
          $(slider)
            .slick({
              slidesToShow: slidesToShow,
              slidesToScroll: slidesToShow,
              dots: dots,
              arrows: arrows,
              autoplay: autoplay,
              centerMode: true,
              centerPadding: '0'
            });
        }
      });
    $(window).scroll();
    $(window).resize();
  },
}
