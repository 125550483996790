<template>
  <a id="backlink" @click="goBack">{{ $t('common.back') }}</a>
</template>

<script>
  export default {
    name: 'BackButton',
    methods: {
      goBack() {
        this.$router.back();
      },
    }
  };
</script>

<style scoped>

</style>
